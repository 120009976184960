export const isAnyFilterSelected = (filterState) => {
  let isSelected = false;
  for (const key in filterState) {
    if (filterState[key].show === true && key !== 'singleFieldSearchFilter') {
      isSelected = true
      break;
    }
  }
  return isSelected
}

export const isAllFilterFilled = (filterState) => {
  let isFilled = true;
  for (const key in filterState) {
    if (filterState[key].show === true) {
      if (key === 'orderDateFilter') {
        if ((filterState.orderDateFilter.valueFrom === 0 || filterState.orderDateFilter.valueTo === 0)) {
          isFilled = false;
        } else {
          try {
            new Date(filterState.orderDateFilter.valueFrom).toISOString();
            new Date(filterState.orderDateFilter.valueTo).toISOString();
          } catch (ex) {
            isFilled = false;
          }
        }
      } else if (filterState[key].value.length === 0) {
        isFilled = false;
      }
      break;
    }
  }
  return isFilled;
}
export const getFilterStateKeyByFilterType = (filter) => {
  let filterStateKey = {
    "singleFieldSearch": "singleFieldSearchFilter",
    "country": "countryFilter",
    "orderStatus": "orderStatusFilter",
    "carriers": "carrierFilter",
    "productType": "productTypeFilter",
    "dateTo": "orderDateFilter",
    "dateFrom": "orderDateFilter",
    "cancellationParty": "cancellationPartyFilter",
    "cancellationReason": "cancellationReasonFilter",
    "deliveryType": "deliveryTypeFilter"
  }
  return filterStateKey[filter];

}
export const checkIsPhoneNumber = (val) => {
  if (val && ((val.includes("dropoff.phoneNumber")) || (val.includes("pickup.phoneNumber")))) {
    return true
  }
  return false;
}

const getAppendedString = (valueArray) => {
  let valueString = '';
  for (let i = 0; i < valueArray.length; i++) {
    valueString += valueArray[i].name;
    if ((valueArray.length > 1) && (i !== (valueArray.length - 1))) {
      valueString += '%7C'
    }
  }
  return valueString;
}

export const createFilterQuery = (filterData) => {
  let finalQueryString = '';
  let searchBy = '';
  let date = '';

  if (filterData) {
    if (filterData.singleFieldSearchFilter && filterData.singleFieldSearchFilter.show) {
      let _key = filterData.singleFieldSearchFilter.key;
      if (filterData.singleFieldSearchFilter.value.length > 0 && _key !== undefined) {
        let _value = filterData.singleFieldSearchFilter.value
        searchBy += `*${_key}:${_value}`
      }
    }
    if (filterData.countryFilter && filterData.countryFilter.show) {
      if (filterData.countryFilter.value.length > 0) {
        let countryArray = filterData.countryFilter.value;
        let countryValue = getAppendedString(countryArray)
        searchBy += '*country:' + countryValue;
      }
    }
    if (filterData.carrierFilter && filterData.carrierFilter.show) {
      if (filterData.carrierFilter.value.length > 0) {
        let carrierArray = filterData.carrierFilter.value;
        let carrierValue = getAppendedString(carrierArray)
        searchBy += '*deliveryPartner.name:' + carrierValue;
      }
    }
    if (filterData.orderStatusFilter && filterData.orderStatusFilter.show) {
      if (filterData.orderStatusFilter.value.length > 0) {
        let orderStatusArray = filterData.orderStatusFilter.value;
        let orderStatusValue = getAppendedString(orderStatusArray)
        searchBy += '*status:' + orderStatusValue;
      }
    }
    if (filterData.cancellationPartyFilter && filterData.cancellationPartyFilter.show) {
      if (filterData.cancellationPartyFilter.value.length > 0) {
        let cancellationPartyArray = filterData.cancellationPartyFilter.value;
        let cancellationPartyValue = getAppendedString(cancellationPartyArray)
        searchBy += '*cancelParty:' + cancellationPartyValue;
      }
    }
    if (filterData.productTypeFilter && filterData.productTypeFilter.show) {
      if (filterData.productTypeFilter.value.length > 0) {
        let productTypeArray = filterData.productTypeFilter.value;
        let productTypeValue = getAppendedString(productTypeArray)
        searchBy += '*claimType:' + productTypeValue;
      }
    }
    if (filterData.cancellationReasonFilter && filterData.cancellationReasonFilter.show) {
      if (filterData.cancellationReasonFilter.value.length > 0) {
        let cancellationReasonValue = filterData.cancellationReasonFilter.value;
        searchBy += '*cancellationReason:' + cancellationReasonValue;
      }
    }
    if (filterData.orderDateFilter && filterData.orderDateFilter.show) {
      let _dateFrom = filterData.orderDateFilter.valueFrom/1000;
      let _dateTo = filterData.orderDateFilter.valueTo/1000;
      if (filterData.orderDateFilter.valueFrom > 0 && filterData.orderDateFilter.valueTo > 0) {
        date = `&dateFrom=${_dateFrom}&dateTo=${_dateTo}`
      }
    }
    if (filterData.deliveryTypeFilter && filterData.deliveryTypeFilter.show) {
      if (filterData.deliveryTypeFilter.value.length > 0) {
        let deliveryTypeArray = filterData.deliveryTypeFilter.value;
        let deliveryTypeValue = getAppendedString(deliveryTypeArray)
        searchBy += '*deliveryType:' + deliveryTypeValue;
      }
    }

    if (searchBy.length > 0) {
      finalQueryString = '&searchBy=' + searchBy.substring(1);
    }
    if (date.length > 0) {
      finalQueryString += date;
    }
  }
  // console.log("finalQueryString =", finalQueryString)
  return finalQueryString;
}

export const mapInternalStatus = (statusValue) => {
  const internalStatus = {
    "created": "Created",
    "assigning": "Assigning Driver",
    "assigned": "Assigned",
    "pickup-underway": "Pick-up Underway",
    "picked": "Picked-Up",
    "delivery-underway": "Delivery Underway",
    "delivered": "Delivered",
    "completed": "Completed",
    "cancelled": "Cancelled",
    "expired": "Expired",
    "rejected": "Rejected",
    "failed": "Failed",
    "replaced": "Replaced",
    "return-underway": "Return Underway",
    "dispatched-to-new-address": "Dispatched to new address",
    "return-started": "Return To Origin Initiated"
  };
  return internalStatus[statusValue];
}

export const RPS_DELIVERY_PARTNER_NAMES = [
  "321 electronics",
  "abtek legazpi",
  "cell active palawan",
  "cellphone kingdom gensan",
  "chronicles sta. ana",
  "fixst la union",
  "fonerange",
  "highlands baguio",
  "jayzertech bacolod",
  "jayzertech batangas",
  "jayzertech bulacan",
  "jayzertech cavite",
  "jayzertech cebu",
  "jayzertech laguna",
  "jayzertech nueva ecija",
  "jayzertech pampanga",
  "jayzertech pangasinan",
  "jayzertech rizal",
  "jayzertech tarlac",
  "jazzy cebu",
  "jazzy iloilo",
  "jazzy laguna",
  "memoxpress",
  "nebsklie quezon",
  "raycon cainta",
  "raycon pampanga",
  "rc goldline cebu",
  "rda mobile davao",
  "techq gadget center albay",
  "techq gadget center batangas",
  "cargo king - cdo",
  "cargo king - cebu",
  "cargo king - davao",
  "cargo king - iloilo",
  "cargo king - bacolod",
  "cargo king - palawan",
  "cargo king - general santos",
  "cargo king - bohol",
  "cargo king - cagayan de oro",
  "cargo king - butuan",
  "cargo king - leyte",
  "abest express"
];

export const mapDeliveryPartnerName = (value) => {
  let deliveryPartner = {
    "lalamove": "Lalamove",
    "borzo": "Borzo",
    "rpx": "RPX",
    "ghtk": "Ghtk",
    "grab express": "Grab Express",
    "consegnia": "Consegnia",
    "ahamove": "Ahamove",
    "nhat tin": "Nhat tin",
    "lbc express - corporate": "LBC Express - Corporate",
    "kerry express": "Kerry Express"
  };
  RPS_DELIVERY_PARTNER_NAMES.forEach((name) => deliveryPartner[name] = name.toUpperCase());
  return deliveryPartner[value]
}

export const mapCancelParty = (name) => {
  switch (name) {
    case "USER":
      return "User Request";
    case "LALAMOVE_CUSTOMER_SUPPORT":
      return "Lalamove Customer Support";
    case "BORZO":
      return "Borzo";
    default:
      return name;
  }
};